import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  name: 'Test',
  applicationName: 'Harrie van Erp - Inventarisatie',
  baseUrl: 'https://harrievanerp.inventorycomplete.test.emendis.nl/api',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: false
  },
  db_name: 'harrievanerp_db',
  clientName: 'harrievanerp'
};
