import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { ProjectService } from '@shared/services/project.service';
import { UserService } from '@shared/services/user.service';
import { Client } from '@domain/models/client.model';
import { combineLatest, Observable } from '@node_modules/rxjs';
import { Project } from '@domain/models/project.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { first } from '@node_modules/rxjs/internal/operators';
import { of } from 'rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-flow',
  templateUrl: 'inventory-flow.component.html',
  styleUrls: ['./inventory-flow.component.scss'],
})
export class InventoryFlowComponent implements OnInit {
  public currentIndex = 0;
  public project: Project;
  public routes: MenuItem[] = [
    { routerLink: 'client', label: 'Klant' },
    { routerLink: 'address', label: 'Adressen' },
    { routerLink: 'contact', label: 'Contactpersonen' },
    { routerLink: 'options', label: 'Opties' },
    { routerLink: 'inventory', label: 'Inventarisatie' },
    { routerLink: 'material', label: 'Materialen' },
    { routerLink: 'overview', label: 'Overzicht' },
    { routerLink: 'quotation', label: 'Offerte' },
    { routerLink: 'pictures', label: 'Bestanden' },
    { routerLink: 'work-assignment', label: 'Werkopdracht' },
    // If you're adding a new tab, please increment $numberOfNavigationTabs in scss file: src/clients/pavanrooyen/styles/variables.scss
  ];
  public client$: Observable<Client>;
  public onlineMode: boolean = true;

  public constructor(
      private router: Router,
      private route: ActivatedRoute,
      private projectService: ProjectService,
      private syncService: SynchronisationService,
      private api: ApiServiceWithLoaderService,
      private user: UserService,
  ) {
    this.api.increaseLoaderValueByOne();

    this.onlineMode = navigator.onLine;

    // Remove quotation route for executor
    if (this.user.hasRole('executor') || this.user.hasRole('executor-mbo')) {
      this.routes = this.routes.filter(r => r.routerLink !== 'quotation');
    }

    this.projectService.projectLoaded.subscribe((project) => {
      this.project = project;

      this.projectService.updateProjectReadOnlyStatus(this.project);
      this.api.decreaseLoaderValueByOne();
    });
  }

  public ngOnInit(): void {
    this.processRouteParams();
    this.setCurrentIndex();

    // Listen to route changes to update current index
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentIndex();
      }
    });
  }

  public next(): void {
    if (this.currentIndex < this.routes.length) {
      this.router.navigate([this.routes[this.currentIndex + 1].routerLink], { relativeTo: this.route });
    }
  }

  public previous(): void {
    if (this.currentIndex > 0) {
      this.router.navigate([this.routes[this.currentIndex - 1].routerLink], {relativeTo: this.route});
    }
  }

  public save(): void {
    // Reload page
    this.router.navigate([this.routes[this.currentIndex].routerLink], {relativeTo: this.route});
  }

  // Read project route id and load project in case of page refresh
  public processRouteParams(): void {
    this.route.params.subscribe(async (params: any) => {
      const projectId = params['project'];

      if (this.route.snapshot.queryParamMap.get('sync')) {
        await this.syncService.loadSingleProjectData(projectId);
      }

      await this.projectService.loadProject(projectId);
    });
  }

  // Set the index according to url
  public setCurrentIndex(): void {
    this.routes.forEach((route: any, index: number) => {
      if (this.router.url.includes(route.routerLink)) {
        this.currentIndex = index;
      }
    });
  }
}
