import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';

// Emendis Blueprint
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { TableModule } from 'primeng/table';

// App services
import { AuthGuard } from '@blueprint/auth/auth.guard';
import { AuthService } from '@blueprint/auth/auth.service';
import { UserService } from '@shared/services/user.service';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { EnvironmentService } from '@shared/services/environment.service';

// App modules/components
import { LayoutsModule } from '@features/gui/layouts/layouts.module';
import { LoginModule } from '@features/login/login.module';
import { UserModule } from '@features/user/user.module';
import { ProjectModule } from '@features/project/project.module';
import { InventoryModule } from '@features/inventory/inventory.module';
import { SettingsModule } from '@features/settings/settings.module';
import { ProjectGuard } from '@shared/guards/project.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { SignaturePadModule } from 'angular2-signaturepad';

/** Sentry init */
import * as Raven from 'raven-js';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignatureService } from '@shared/services/signature.service';
import { ManageModule } from "@features/manage/manage.module";
import { OnlineStatusService } from '@shared/services/online-status.service';
import { ProgressSpinnerModule } from '@node_modules/primeng/components/progressspinner/progressspinner';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

if (environment.production) {
  Raven.config(
    'https://31e4670c418e4d2f8f4b4edd09b77a26@sentry.emendis.nl/41'
  ).install();
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      throw err;
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    LayoutsModule,
    EmendisBlueprintModule,
    LoginModule,
    UserModule,
    ProjectModule,
    ManageModule,
    SettingsModule,
    InventoryModule,
    SignaturePadModule,
    TableModule,
    RouterModule.forRoot(ROUTES, {enableTracing: false}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ProgressSpinnerModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ProjectGuard,
    ClientGuard,
    UserGuard,
    AddressGuard,
    ContactGuard,
    ContactListGuard,
    OptionsGuard,
    AuthGuard,
    InventoryGuard,
    QuotationGuard,
    BootstrapGuard,
    AuthService,
    DataService,
    UserService,
    SynchronisationService,
    EnvironmentService,
    SignatureService,
    OnlineStatusService,
    ApiServiceWithLoaderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
