import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Address } from '@domain/models/address.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';

@Component({
  selector: 'app-inventory-options-detail',
  templateUrl: 'options-detail.component.html'
})
export class InventoryOptionsDetailComponent implements OnInit, OnDestroy {
  public form: any;
  public project = new Project({});
  public activities: ProjectActivity[] = [];
  public specialties: ProjectSpecialty[] = [];
  public disabled: boolean = true;
  public addresses: Address[] = [];
  public addressOptions: { label: string, value: string }[] = [];
  public displayActivityArray: any;
  public value: Date;
  public numberTrucks: SelectItem[];
  public numberMovers: SelectItem[];
  public localeNL: CalendarLocale = new CalendarLocale();

  private subscriptionProjectLoaded: Subscription;

  public constructor(private projectService: ProjectService,
                     private formBuilder: FormBuilder) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.numberMovers = [];
    this.numberMovers.push({ label: '1', value: '1' });
    this.numberMovers.push({ label: '2', value: '2' });
    this.numberMovers.push({ label: '3', value: '3' });
    this.numberMovers.push({ label: '4', value: '4' });
    this.numberMovers.push({ label: '5', value: '5' });
    this.numberMovers.push({ label: '8', value: '8' });
    this.numberMovers.push({ label: '10', value: '10' });
    this.numberMovers.push({ label: '15', value: '15' });
    this.numberMovers.push({ label: '20', value: '20' });
    this.numberMovers.push({ label: '25', value: '25' });
    this.numberMovers.push({ label: '50', value: '50' });

    this.numberTrucks = this.numberMovers;
    this.displayActivityArray = [];
  }

  public async ngOnInit(): Promise<void> {
    this.project = this.projectService.getProject();
    this.addresses = this.project.addresses;
    this.specialties = this.project.specialties;
    this.activities = this.project.activities;

    this.updateActivities();
    this.initAddresses();

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(async (project: Project) => {
      this.project = project;
      this.addresses = project.addresses;
      this.specialties = this.project.specialties;
      this.activities = this.project.activities;

      this.projectService.setCurrentClient(this.project.client);
      this.updateActivities();
      this.initForm();
      this.initAddresses();
    });

    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      own_description_activities: this.formBuilder.control(this.project.own_description_activities || ''),
      client_description_activities: this.formBuilder.control(this.project.client_description_activities || ''),
      insurance_certificate_link: this.formBuilder.control(this.project.insurance_certificate_link || ''),
      delivery_date: this.formBuilder.control(this.project.delivery_date || ''),
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.projectService.project) {
      this.projectService.project.own_description_activities = this.form.value.own_description_activities;
      this.projectService.project.client_description_activities = this.form.value.client_description_activities;
      this.projectService.project.delivery_date = this.form.value.delivery_date;

      await this.projectService.saveActivities(this.activities);
      await this.projectService.saveSpecialties(this.specialties);
      this.projectService.setProjectUpdated();
      await this.projectService.saveProject();
    }
  }

  public setApplicable(activities: any): void {
    activities.forEach((activityItem: ProjectActivity) => {
      activityItem.applicable = activities.applicable;
    });
  }

  public async onRemoveActivityClick(activity: ProjectActivity): Promise<void> {
    if (!this.disabled) {
      const index = this.project.activities.indexOf(activity);
      this.project.activities.splice(index, 1);

      // Also delete from displayArray
      const indexDisplayArray = this.displayActivityArray[activity.activity_id].indexOf(activity);

      this.displayActivityArray[activity.activity_id].splice(indexDisplayArray, 1);

      // Delete from database
      await this.projectService.deleteProjectActivity(activity.id);

      this.updateActivities();
      this.projectService.setProjectUpdated();
    }
  }

  public async onAddActivityClick(type: number): Promise<void> {
    if (!this.disabled) {
      const newActivity = new ProjectActivity({
        project_id: this.project.id, activity_id: type, applicable: true
      });

      await newActivity.init();

      this.project.activities.push(newActivity);
      this.updateActivities();
      this.projectService.setProjectUpdated();
    }
  }

  private updateActivities(): void {
    if (!this.project || !this.project.activities) {
      return;
    }

    const activityIdArray = [];
    // Slice original array to remove reference
    const array = this.project.activities.slice();

    // Read all activity ID's and put them in list to check later
    for (const element of array) {
      if (!activityIdArray.includes(element.activity_id)) {
        activityIdArray.push({
          id: element.activity_id,
          name: element.activity.name,
          applicable: element.applicable || false
        });
      }
    }

    // Check for all activity ID's in list, put every activity in array to corresponding activity ID
    for (const key of activityIdArray) {
      this.displayActivityArray[key.id] = array.filter((activity) => activity.activity_id === key.id);
      this.displayActivityArray[key.id].name = key.name;
      this.displayActivityArray[key.id].applicable = false;

      for (const item of this.displayActivityArray[key.id]) {
        if (item.applicable) {
          this.displayActivityArray[key.id].applicable = true;
        }
      }
    }
  }

  private initAddresses(): void {
    this.addressOptions = [];
    for (const address of this.addresses) {
      if (address && address.getDisplayName()) {
        this.addressOptions.push({ label: address.getDisplayName(), value: address.id });
      }
    }
  }
}

