import { DomainModel } from '@domain/domain.model';
import { QuotationTask } from '@domain/models/quotation-task.model';
import { QuotationMaterial } from '@domain/models/quotation-material.model';
import * as uuid from 'uuid/v4';

export class Quotation extends DomainModel {
  // Configuration
  public entity = 'quotation';
  public table = 'quotations';
  public schema = 'id, remote_id, project_id, reference';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public template_code: string = 'standard';
  public reference?: string;
  public status?: string;
  public calculated_volume?: number;
  public total_volume?: number;
  public estimated_distance?: number;
  public distance?: number;
  public estimated_distance_km?: number;
  public distance_km?: number;
  public base_price?: number;
  public packing_fragile_price?: number;
  public unpacking_fragile_price?: number;
  public packing_complete_price?: number;
  public unpacking_complete_price?: number;
  public specials_price?: number;
  public assembly_price?: number;
  public packing_price?: number;
  public coordinator_price?: number;
  public guarantee_certificate_price?: number;
  public handyman_certificate_price?: number;
  public estimated_weeks_in_storage?: number;
  public storage_week_text?: string;
  public storage_week_total_price?: number;
  public storage_handling_price?: number;
  public storage_insurance_price?: number;
  public storage_insurance_total_price?: number;
  public storage_value_price?: number;
  public storage_value_total_price?: number;
  public parking_waiver_price?: number;
  public custom_option_1_name?: string;
  public custom_option_2_name?: string;
  public custom_option_3_name?: string;
  public custom_option_4_name?: string;
  public custom_option_5_name?: string;
  public custom_option_1_price?: number;
  public custom_option_2_price?: number;
  public custom_option_3_price?: number;
  public custom_option_4_price?: number;
  public custom_option_5_price?: number;

  public mount_unmount_ict?: number;
  public cable_management?: number;
  public add_protection?: number;
  public moving_truck_15_cubic?: number;
  public moving_truck_40_cubic?: number;
  public mover?: number;

  public floor_surcharge?: number;
  public elevator_surcharge?: number;
  public piano_safe_surcharge?: number;

  public piano_grand_organ_price?: number;
  public remove_lights_curtains_price?: number;

  public subtotal_price?: number;
  public vat_price?: number;
  public total_price?: number;

  public materials: QuotationMaterial[] = [];
  public tasks: QuotationTask[] = [];

  public invoice_name?: string;
  public invoice_attn?: string;

  public signatureClientDate?: Date;
  public signatureValuatorDate?: Date;

  public signature_client_date?: string;
  public signature_valuator_date?: string;

  public signature_client_image?: string;
  public signature_valuator_image?: string;

  public accept_actual_avvv: boolean;
  public accept_timely_payment: boolean;
  public accept_insurance_avvb: boolean;
  public accept_client_data: boolean;
  public accept_guarantee_certificate: boolean;
  public manually_changed_base_price: boolean;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    this.signatureClientDate = this.signature_client_date
      ? new Date(this.signature_client_date)
      : null;
    this.signatureValuatorDate = this.signature_valuator_date
      ? new Date(this.signature_valuator_date)
      : null;

    if (!this.id) {
      return;
    }

    // Set relations
    const quotationMaterials = await QuotationMaterial.query
      .where('quotation_id')
      .equals(this.id)
      .toArray();
    this.materials = [];
    for (const quotationMaterial of quotationMaterials) {
      await quotationMaterial.init();
      this.materials.push(quotationMaterial);
    }

    const quotationTasks = await QuotationTask.query
      .where('quotation_id')
      .equals(this.id)
      .toArray();
    this.tasks = [];
    for (const quotationTask of quotationTasks) {
      await quotationTask.init();
      this.tasks.push(quotationTask);
    }
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      template_code: this.template_code,
      reference: this.reference,
      status: this.status,
      calculated_volume: this.calculated_volume,
      total_volume: this.total_volume,
      estimated_distance: this.estimated_distance,
      distance: this.distance,
      estimated_distance_km: this.estimated_distance_km,
      distance_km: this.distance_km,
      base_price: this.base_price,
      packing_fragile_price: this.packing_fragile_price,
      unpacking_fragile_price: this.unpacking_fragile_price,
      packing_complete_price: this.packing_complete_price,
      unpacking_complete_price: this.unpacking_complete_price,
      specials_price: this.specials_price,
      assembly_price: this.assembly_price,
      packing_price: this.packing_price,
      coordinator_price: this.coordinator_price,
      guarantee_certificate_price: this.guarantee_certificate_price,
      handyman_certificate_price: this.handyman_certificate_price,
      estimated_weeks_in_storage: this.estimated_weeks_in_storage || 0,
      storage_week_text: this.storage_week_text,
      storage_week_total_price: this.storage_week_total_price,
      storage_handling_price: this.storage_handling_price,
      storage_insurance_price: this.storage_insurance_price,
      storage_insurance_total_price: this.storage_insurance_total_price,
      storage_value_price: this.storage_value_price,
      storage_value_total_price: this.storage_value_total_price,
      parking_waiver_price: this.parking_waiver_price,
      mount_unmount_ict: this.mount_unmount_ict || 0,
      cable_management: this.cable_management || 0,
      add_protection: this.add_protection || 0,
      moving_truck_15_cubic: this.moving_truck_15_cubic || 0,
      moving_truck_40_cubic: this.moving_truck_40_cubic || 0,
      mover: this.mover || 0,
      piano_grand_organ_price: this.piano_grand_organ_price || 0,
      remove_lights_curtains_price: this.remove_lights_curtains_price || 0,
      custom_option_1_name: this.custom_option_1_name,
      custom_option_2_name: this.custom_option_2_name,
      custom_option_3_name: this.custom_option_3_name,
      custom_option_4_name: this.custom_option_4_name,
      custom_option_5_name: this.custom_option_5_name,
      custom_option_1_price: this.custom_option_1_price,
      custom_option_2_price: this.custom_option_2_price,
      custom_option_3_price: this.custom_option_3_price,
      custom_option_4_price: this.custom_option_4_price,
      custom_option_5_price: this.custom_option_5_price,
      floor_surcharge: this.floor_surcharge,
      elevator_surcharge: this.elevator_surcharge,
      piano_safe_surcharge: this.piano_safe_surcharge,
      subtotal_price: this.subtotal_price,
      vat_price: this.vat_price,
      total_price: this.total_price,
      invoice_name: this.invoice_name,
      invoice_attn: this.invoice_attn,
      signature_client_image: this.signature_client_image,
      signature_valuator_image: this.signature_valuator_image,
      signature_client_date: this.formatDate(this.signatureClientDate),
      signature_valuator_date: this.formatDate(this.signatureValuatorDate),
      accept_actual_avvv: this.accept_actual_avvv,
      accept_timely_payment: this.accept_timely_payment,
      accept_insurance_avvb: this.accept_insurance_avvb,
      accept_client_data: this.accept_client_data,
      accept_guarantee_certificate: this.accept_guarantee_certificate,
      manually_changed_base_price: this.manually_changed_base_price,
    };
  }

  public updateDate() {
    this.signature_client_date = this.formatDate(this.signatureClientDate);
    this.signature_valuator_date = this.formatDate(this.signatureValuatorDate);
  }
}
