import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact } from '@domain/models/contact.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { customEmailValidator } from '@shared/services/custom-validators.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { ContactTemplate } from "@domain/models/contact-template.model";
import { SelectItem } from "@node_modules/primeng/components/common/selectitem";
import * as cloneDeep from 'lodash/cloneDeep';
import { DataService } from "@shared/services/data.service";
import * as uuid from 'uuid/v4';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-contacts-detail',
  templateUrl: 'contact-detail.component.html'
})
export class InventoryContactsDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public showErrors: boolean = false;
  public errors: any = {};
  public disabled: boolean = false;
  public project = new Project({});
  public contactTemplates: ContactTemplate[] = [];
  public contactTemplatesList: SelectItem[] = [];
  public companiesList: SelectItem[] = [];
  public contactTemplatesListDefault: SelectItem[] = [];
  public environment: object;
  public mode = { isAdd: true, alertText: 'Contact succesvol toegevoegd' };
  public genders: SelectItem[] = [];

  private contact = new Contact({});
  private routeContactId;
  private subscriptionContactsChanged: Subscription;

  public constructor(private api: ApiServiceWithLoaderService,
                     private router: Router,
                     private route: ActivatedRoute,
                     private dataService: DataService,
                     private formBuilder: FormBuilder,
                     private projectService: ProjectService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });

    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    this.contactTemplates = await ContactTemplate.query.toArray();

    this.contactTemplates.forEach((contactTemplate: ContactTemplate) => {
      this.contactTemplatesList.push({
        label: contactTemplate.name + ', ' + contactTemplate.email + ' (' + contactTemplate.company + ')',
        value: contactTemplate.id});

      if (!this.companiesList.find((company: SelectItem) => company.value === contactTemplate.company)) {
        this.companiesList.push({
          label: contactTemplate.company,
          value: contactTemplate.company
        });
      }
    });

    this.loadLists();

    this.companiesList = this.dataService.sortDropdownByLabel(this.companiesList);
    this.contactTemplatesList = this.dataService.sortDropdownByLabel(this.contactTemplatesList);

    this.contactTemplatesListDefault = cloneDeep(this.contactTemplatesList);
    this.project = this.projectService.getProject();

    this.initForm();

    this.route.params.subscribe(async (params: any) => {
      this.routeContactId = params['id'];

      if (this.routeContactId) {
        this.mode.isAdd = false;

        this.subscriptionContactsChanged = this.projectService.contactsChanged.subscribe((contact: Contact) => {
          this.contact = contact;
          this.updateForm();
        });

        await this.projectService.getContact(this.routeContactId);
      }

      this.projectService.projectLoaded.subscribe((project: Project) => {
        this.project = project;
        this.updateForm();
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionContactsChanged) {
      this.subscriptionContactsChanged.unsubscribe();
    }
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control({value: this.contact.id, disabled: this.disabled}),
      client_id: this.formBuilder.control({value: this.project.client.id || null, disabled: this.disabled}),
      company_id: this.formBuilder.control({ value: this.contactTemplates, disabled: this.disabled }),
      contact_template_id: this.formBuilder.control({value: this.contactTemplates, disabled: this.disabled}),
      name: this.formBuilder.control({value: this.contact.name || '', disabled: this.disabled}, Validators.required),
      initials: this.formBuilder.control({value: this.contact.initials || '', disabled: this.disabled}),
      gender: this.formBuilder.control({value: this.contact.gender || 'F', disabled: this.disabled}, Validators.required),
      email: this.formBuilder.control({
        value: this.contact.email || '',
        disabled: this.disabled
      }, [customEmailValidator]),
      phone: this.formBuilder.control({value: this.contact.phone || '', disabled: this.disabled}, Validators.required),
      mobile_phone: this.formBuilder.control({value: this.contact.mobile_phone || '', disabled: this.disabled}),
      remarks: this.formBuilder.control({value: this.contact.remarks, disabled: this.disabled}),
    });
  }

  public onSubmit(): void {
    if (!this.disabled) {
      if (this.form.valid) {
        this.contact = this.form.value;

        if (this.mode.isAdd) {
          this.contact.id = uuid();
        }

        this.projectService.saveContact(this.contact);
        this.onCloseClick();
      } else {
        this.showErrors = true;
      }
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact');
  }

  public selectContactTemplate(contactTemplateId: string): void {
    if (contactTemplateId) {
      this.contact.id = contactTemplateId;

      this.updateFormToContactTemplate(this.contactTemplates.find((contactTemplate: ContactTemplate) => contactTemplate.id === contactTemplateId));
    }
  }

  public updateContactTemplatesList(selectedCompany: string): void {
    if (selectedCompany === null) {
      this.contactTemplatesList = cloneDeep(this.contactTemplatesListDefault);
    } else {
      this.contactTemplatesList = this.contactTemplatesListDefault.filter((address: SelectItem) => {
        if (address) {
          if (address.label.substring(address.label.lastIndexOf('(') + 1, address.label.lastIndexOf(')')) === selectedCompany) {
            return address;
          }
        }

        return;
      });
    }
  }

  private async loadLists(): Promise<void> {
    this.genders = [
      { label: 'Familie', value: 'F' },
      { label: 'De heer', value: 'M' },
      { label: 'Mevrouw', value: 'V' },
    ];
  }

  private updateFormToContactTemplate(contactTemplate: ContactTemplate): void {
    if (contactTemplate) {
      this.form.patchValue(contactTemplate);
    }
  }

  /**
   * Update the form information
   */
  private updateForm(): void {
    this.form.reset({
      id: {value: this.contact.id, disabled: this.disabled},
      client_id: {value: this.project.client.id || null, disabled: this.disabled},
      contact_template_id: {value: this.contactTemplates, disabled: this.disabled},
      name: {value: this.contact.name || '', disabled: this.disabled},
      initials: {value: this.contact.initials || '', disabled: this.disabled},
      gender: {value: this.contact.gender || 'F', disabled: this.disabled},
      email: {value: this.contact.email || '', disabled: this.disabled},
      phone: {value: this.contact.phone || '', disabled: this.disabled},
      mobile_phone: {value: this.contact.mobile_phone || '', disabled: this.disabled},
      remarks: {value: this.contact.remarks, disabled: this.disabled},
    });
  }
}
