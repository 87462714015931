import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '@domain/models/client.model';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Subscription ,  BehaviorSubject } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { environment } from '@environments/environment';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import * as moment from '@node_modules/moment';

@Component({
  selector: 'app-inventory-client',
  templateUrl: 'client.component.html',
  styleUrls: ['./client.component.scss']
})

/**
 * InventoryClientComponent
 */
export class InventoryClientComponent implements OnInit, OnDestroy {
  public form: any;
  public errors: any = {};
  public showErrors = false;
  public client = new Client({});
  public project = new Project({});
  public result: any;
  public accountManagersList: SelectItem[] = [];
  public executorList: SelectItem[] = [];
  public statusList: SelectItem[] = [];
  public relationGroups: SelectItem[] = [];
  public locations: SelectItem[] = [];
  public environment: any;
  public disabled = false;
  public localeNL: CalendarLocale = new CalendarLocale();

  private canChangeStatus: boolean;
  private subscriptionProjectLoaded: Subscription;
  private subscriptionClientChanged: Subscription;
  private defaultUser: any;

  private disabledForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {ProjectService} projectService
   * @param {DataService} dataService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param userService
   * @param synchronisationService
   */
  public constructor(private formBuilder: FormBuilder,
                     private projectService: ProjectService,
                     private dataService: DataService,
                     private route: ActivatedRoute,
                     private router: Router,
                     private userService: UserService,
                     private synchronisationService: SynchronisationService) {
    this.defaultUser = null;
    this.canChangeStatus = true;
    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadLists();

    this.project = this.projectService.getProject();
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;

      this.disabledForm$.next(this.disabled);
    });

    if (this.project.client) {
      this.client = this.project.client;
    }

    // In case client has changed (selected from popup)
    this.subscriptionClientChanged = this.projectService.clientChanged.subscribe(
      (client: Client) => {
        this.project.client_id = client.id;
        this.project.client = client;
        this.client = client;
        this.updateForm();
      }
    );

    // In case project loaded (in case of refresh)
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(
      (project: Project) => {
        this.project = project;

        if (this.project.client) {
          this.client = this.project.client;
        } else {
          this.client = new Client({});
        }
        this.projectService.setCurrentClient(this.client);
        this.updateForm();
      }
    );

    this.initForm();
    this.updateForm();

    this.disabledForm$.subscribe(async _ => {
      /** Only check for this if project is booked */
      if (this.disabled) {
        this.canChangeStatus = this.userService.isAdministrator();
        await this.updateForm();
      }
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionClientChanged) {
      this.subscriptionClientChanged.unsubscribe();
    }

    await this.saveClient();

    this.destroy$.next(true);
  }

  public setType(type): void {
    this.projectService.project.type = type;
    switch (type) {
      case 'business':
        this.projectService.project.projectType = 'Zakelijk';
        break;
      case 'private':
        this.projectService.project.projectType = 'Particulier';
        break;
      default:
        this.projectService.project.projectType = '?';
        break;
    }
  }

  private async loadLists(): Promise<void> {
    let queryOptions = new QueryOptions({
      sortColumn: 'name',
      sortOrder: 'asc',
      usePaging: false
    });
    let result = await this.dataService.get(
      'locations',
      queryOptions,
      '/location-group/list'
    );
    this.locations = [];
    result.forEach((item) => {
      this.locations.push({ label: item.name, value: item.id });
    }, this);

    result = await this.dataService.get(
      'relation_groups',
      queryOptions,
      '/relation-group/list'
    );
    this.relationGroups = [];
    result.forEach((item) => {
      this.relationGroups.push({ label: item.name, value: item.id });
    }, this);

    queryOptions = new QueryOptions({
      sortColumn: 'name_lcase',
      usePaging: false
    });
    result = await this.dataService.get('users', queryOptions, '/users/list');

    this.accountManagersList = [];
    result.forEach((user) => {
      if (user && user.is_accountmanager) {
        this.accountManagersList.push({ label: user.name, value: user.id });
      }
    }, this);

    if (!this.project.accountmanager_id) {
      const selectedUser = result.find((user) => user.id === JSON.parse(localStorage.getItem('userId')));
      if (selectedUser) {
        this.defaultUser = selectedUser.id;
      }
    }
    this.accountManagersList.sort((one, two) => (one > two ? 1 : -1));

    this.executorList = [];
    result.forEach((item) => {
      if (item.role.code === 'transporter' || item.role.code === 'executor' || item.role.code === 'executor_mbo') {
        this.executorList.push({ label: item.name, value: item.id });
      }
    }, this);

    this.statusList = Project.getStatusList();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      accountmanager_id: this.formBuilder.control({
        value: this.defaultUser || this.project.accountmanager_id, disabled: this.disabled}, [
          Validators.required
      ]),
      executor_id: this.formBuilder.control({
        value: this.project.executor_id, disabled: this.disabled
      }),
      name: this.formBuilder.control({
        value: this.client.name ? this.client.name : '', disabled: this.disabled }, [
          Validators.required,
          Validators.maxLength(255)
      ]),
      delivery_date: this.formBuilder.control({
        value: this.project.delivery_date ? new Date(this.project.delivery_date) : null, disabled: this.disabled }),
      location_id: this.formBuilder.control({
        value: this.client.location_id || '', disabled: this.disabled }, [
          Validators.required
      ]),
      relation_group_id: this.formBuilder.control({
        value: this.client.relation_group_id ? this.client.relation_group_id : '', disabled: this.disabled }, [
          Validators.required
      ]),
      status: this.formBuilder.control({ value: this.project.status || '', disabled: !this.canChangeStatus }),
      description: this.formBuilder.control({
        value: this.client.description || '', disabled: this.disabled
      }),
      remarks: this.formBuilder.control({ value: this.client.remarks || '', disabled: this.disabled })
    });
  }

  private updateForm(): void {
    this.form.reset({
      accountmanager_id: { value: this.defaultUser || this.project.accountmanager_id, disabled: this.disabled },
      executor_id: { value: this.project.executor_id, disabled: this.disabled },
      name: { value: this.client.name ? this.client.name : '', disabled: this.disabled },
      location_id: { value: this.client.location_id || this.locations[0].value, disabled: this.disabled },
      relation_group_id: { value: this.client.relation_group_id ? this.client.relation_group_id : '', disabled: this.disabled },
      status: { value: this.project.status || '', disabled: !this.canChangeStatus },
      description: { value: this.client.description || '', disabled: this.disabled },
      delivery_date: { value: this.project.delivery_date ? new Date(this.project.delivery_date) : null, disabled: this.disabled },
      remarks: { value: this.client.remarks || '', disabled: this.disabled }
    });
  }

  private async saveClient(): Promise<void> {
    if (this.form.valid) {
      this.client.name = this.form.get('name').value;
      this.client.location_id = this.form.get('location_id').value;
      this.client.relation_group_id = this.form.get('relation_group_id').value;
      this.client.description = this.form.get('description').value;
      this.client.remarks = this.form.get('remarks').value;

      this.project.client = this.client;
      this.project.status = this.form.get('status').value;
      this.project.accountmanager_id = this.form.get('accountmanager_id').value;
      this.project.executor_id = this.form.get('executor_id').value;
      this.project.delivery_date = this.form.get('delivery_date').value ? moment(this.form.get('delivery_date').value).format('YYYY-MM-DD') : null;

      if (!this.project.delivery_date || !moment(this.project.delivery_date, 'YYYY-MM-DD', true).isValid()) {
        this.project.delivery_date = null;
      }

      await this.projectService.saveClientAndProject();

      this.projectService.setCurrentClient(this.client);
    }
  }
}
